import React from "react";
import AssessmentList from "./TeacherDash/AssessmentList";
import TodoList from "./TeacherDash/TodoList";
import AssessmentsGraph from "./TeacherDash/AssessmentsGraph";
import { Grid, Container } from "@mui/material";
import AssessmentsTable from "./TeacherDash/AssessmentsTable";
function TeacherDashboardInsightPage() {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <AssessmentList itemsToShow={2} />
        </Grid>
        <Grid item sm={8}>
          <AssessmentsGraph />
        </Grid>
        <Grid item sm={4}>
          <TodoList visibleCount={4} />
        </Grid>
        <Grid item sm={8}>
          <AssessmentsTable rowsToShow={3} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default TeacherDashboardInsightPage;
