import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Slider,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const AssessmentsTable = ({ rowsToShow: initialRowsToShow = 5 }) => {
  const [assessments, setAssessments] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowsToShow, setRowsToShow] = useState(initialRowsToShow);
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false); // Modal state
  const [selectedAssessment, setSelectedAssessment] = useState(null); // State for selected assessment to update progress
  const [newProgress, setNewProgress] = useState(0); // New grading progress value

  const fetchAssessments = async () => {
    try {
      const userEmail = Cookies.get("email");
      const response = await axios.get(
        `https://eduevaluator.com/api/teachercal/assessments?userEmail=${userEmail}`
      );
      setAssessments(response.data);
    } catch (error) {
      console.error("Error fetching assessments:", error);
    }
  };

  useEffect(() => {
    fetchAssessments();
  }, []);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleShowAll = () => {
    setOpenModal(true); // Open the modal when "Show All" is clicked
    setAnchorEl(null);
  };

  const handleModalClose = () => setOpenModal(false); // Close the modal

  const handlePaginationChange = (event, page) => setCurrentPage(page);

  const handleProgressChange = (event, newValue) => {
    setNewProgress(newValue);
  };

  const handleSaveProgress = async () => {
    if (selectedAssessment) {
      try {
        // Send PUT request to update the grading progress
        const response = await axios.put(
          `https://eduevaluator.com/api/teachercal/assessment/${selectedAssessment._id}/updateGradingProgress`,
          { gradingProgress: newProgress }
        );

        // Update the local assessments state with the new progress
        const updatedAssessments = assessments.map((assessment) =>
          assessment._id === selectedAssessment._id
            ? { ...assessment, gradingProgress: newProgress }
            : assessment
        );
        setAssessments(updatedAssessments);

        // Reset the state after saving
        setSelectedAssessment(null);
        setNewProgress(0); // Reset progress value

        // Optionally, show a success message
        toast.success("Grading progress updated successfully!");
      } catch (error) {
        console.error("Error updating grading progress:", error);
        toast.error("Failed to update grading progress.");
      }
    }
  };

  const displayedRows = assessments.slice(
    (currentPage - 1) * rowsToShow,
    currentPage * rowsToShow
  );

  const handleModalPaginationChange = (event, page) => setCurrentPage(page);

  return (
    <Card
      style={{
        padding: "20px",
        backgroundColor: "#f5f5f5",
        boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        height: "auto",
        position: "relative",
      }}
    >
      <ToastContainer />
      <Box>
        <IconButton
          onClick={handleMenuOpen}
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            backgroundColor: "#4286F0",
            color: "white",
            borderRadius: "50%",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleShowAll}>Show All</MenuItem>
        </Menu>
      </Box>
      <Typography variant="h6" align="center" sx={{ marginBottom: "20px" }}>
        Assessment Grading Progress
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "darkgrey" }}>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Class
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Type
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Date
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Time
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Grade Level
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Grading Progress
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Update Progress
              </TableCell>{" "}
              {/* New column */}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} style={{ textAlign: "center" }}>
                  No data available
                </TableCell>
              </TableRow>
            ) : (
              displayedRows.map((assessment) => (
                <TableRow key={assessment._id}>
                  <TableCell>{assessment.title}</TableCell>
                  <TableCell>{assessment.type}</TableCell>
                  <TableCell>{assessment.date}</TableCell>
                  <TableCell>{assessment.time}</TableCell>
                  <TableCell>{assessment.gradeLevel}</TableCell>
                  <TableCell>
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        variant="determinate"
                        value={assessment.gradingProgress + 10}
                        size={40}
                        thickness={5}
                        sx={{
                          color:
                            assessment.gradingProgress > 50
                              ? "#4caf50"
                              : "#3f51b5",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          sx={{ color: "text.secondary" }}
                        >
                          {`${Math.round(assessment.gradingProgress)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Button
                      style={{
                        marginTop: 10,
                        width: 100,
                        backgroundColor: "#4286F0",
                        color: "white",
                        borderRadius: 20,
                      }}
                      onClick={() => {
                        setSelectedAssessment(assessment);
                        setNewProgress(assessment.gradingProgress);
                      }}
                    >
                      Update
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {rowsToShow < assessments.length && (
        <Box sx={{ marginTop: 2, display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(assessments.length / rowsToShow)}
            page={currentPage}
            onChange={handlePaginationChange}
            color="primary"
          />
        </Box>
      )}

      {/* Modal for Show All */}
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>All Assessments</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "darkgrey" }}>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Class
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Type
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Date
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Time
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Grade Level
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Grading Progress
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Update Progress
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assessments.map((assessment) => (
                  <TableRow key={assessment._id}>
                    <TableCell>{assessment.title}</TableCell>
                    <TableCell>{assessment.type}</TableCell>
                    <TableCell>{assessment.date}</TableCell>
                    <TableCell>{assessment.time}</TableCell>
                    <TableCell>{assessment.gradeLevel}</TableCell>
                    <TableCell>
                      <Box
                        sx={{ position: "relative", display: "inline-flex" }}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={assessment.gradingProgress + 10}
                          size={40}
                          thickness={5}
                          sx={{
                            color:
                              assessment.gradingProgress > 50
                                ? "#4caf50"
                                : "#3f51b5",
                          }}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="caption"
                            component="div"
                            sx={{ color: "text.secondary" }}
                          >
                            {`${Math.round(assessment.gradingProgress)}%`}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Button
                        style={{
                          marginTop: 10,
                          width: 100,
                          backgroundColor: "#4286F0",
                          color: "white",
                          borderRadius: 20,
                        }}
                        onClick={() => {
                          setSelectedAssessment(assessment);
                          setNewProgress(assessment.gradingProgress);
                        }}
                      >
                        Update
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ marginTop: 2, display: "flex", justifyContent: "center" }}>
            <Pagination
              count={Math.ceil(assessments.length / rowsToShow)}
              page={currentPage}
              onChange={handleModalPaginationChange}
              color="primary"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Updating Progress */}
      <Dialog
        open={selectedAssessment !== null}
        onClose={() => setSelectedAssessment(null)}
        fullWidth
      >
        <DialogTitle>Update Grading Progress</DialogTitle>
        <DialogContent>
          {selectedAssessment && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress
                variant="determinate"
                value={newProgress}
                size={100}
                thickness={5}
                sx={{
                  color: newProgress > 50 ? "#4caf50" : "#3f51b5",
                  marginBottom: 2,
                }}
              />
              <Typography variant="h6">{`${newProgress}%`}</Typography>
              <Slider
                value={newProgress}
                onChange={handleProgressChange}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                step={5}
                min={0}
                max={100}
                sx={{ width: "80%", marginTop: 2 }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedAssessment(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveProgress} color="primary">
            Save Progress
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default AssessmentsTable;
