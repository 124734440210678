import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import ListItem from "@mui/material/ListItem";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import ListItemButton from "@mui/material/ListItemButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import logo from "../../Images/logo.png";
import QuizIcon from "@mui/icons-material/Quiz";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import PaymentIcon from "@mui/icons-material/Payment";
import Cookies from "js-cookie";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import Chip from "@mui/material/Chip";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import LockIcon from "@mui/icons-material/Lock";

const email = Cookies.get("email");
const portalURL = `https://billing.stripe.com/p/login/test_cN2156eyz2Kjbok4gg?prefilled_email=${encodeURIComponent(
  email
)}`;
// PROD const portalURL = `https://billing.stripe.com/p/login/8wMfZE30jagl44o5kk?prefilled_email=${encodeURIComponent(email)}`;

const groupedItems = [
  {
    text: "Evaluation Report",
    icon: <LockIcon />,
    route: "/dashboard",
    requiresElite: true,
  },
  {
    text: "Teacher Profile",
    icon: <LockIcon />,
    route: "/teacherMenu",
    requiresElite: true,
  },
  {
    text: "Evaluation Scheduler",
    icon: <LockIcon />,
    route: "/scheduler",
    requiresElite: true,
  },
  {
    text: "Student Attendance",
    icon: <LockIcon />,
    route: "/attendance",
    requiresElite: true,
  },
  {
    text: "Student of Concern",
    icon: <LockIcon />,
    route: "/studentOfConcern",
    requiresElite: true,
  },
  {
    text: "Teacher Calcalender",
    icon: <EditCalendarIcon />,
    route: "/teacherCalender",
    requiresElite: false,
  },
  {
    text: "Teacher Event Dashboard",
    icon: <SpaceDashboardIcon />,
    route: "/teacherDashboard",
    requiresElite: false,
  },
];

const otherItems = [
  {
    text: "Teacher and Admin Tools",
    icon: <HomeRoundedIcon />,
    route: "/adminTools",
    requiresElite: false,
  },
  {
    text: "Tools",
    icon: <EscalatorWarningIcon />,
    route: "/curriculumEdgeTools",
  },
  {
    text: "Flash Cards",
    icon: <AutoStoriesIcon />,
    route: "/flashCard",
    requiresElite: false,
  },
  {
    text: "Billing",
    icon: <PaymentIcon />,
    url: portalURL,
  },
  // This item is conditionally added
  {
    text: "Subscription",
    icon: <UnsubscribeIcon />,
    route: "/subscrption",
    requiresNotSubscribed: true, // Add custom condition here
  },
  { text: "Settings", icon: <SettingsRoundedIcon />, route: "/settings" },
  { text: "Feedback", icon: <HelpRoundedIcon />, route: "/feedback" },
];

const mainListItems = [
  
  {
    text: "Evaluation Report",
    icon: <HomeRoundedIcon />,
    route: "/dashboard",
    requiresElite: true,
  },
  {
    text: "Teacher Profile",
    icon: <CastForEducationIcon />,
    route: "/teacherMenu",
    requiresElite: true,
  },
  {
    text: "Evaluation Scheduler",
    icon: <CalendarMonthIcon />,
    route: "/scheduler",
    requiresElite: true,
  },
  {
    text: "Tools",
    icon: <EscalatorWarningIcon />,
    route: "/curriculumEdgeTools",
  },
  {
    text: "Student Attendance",
    icon: <CoPresentIcon />,
    route: "/attendance",
    requiresElite: true,
  },
  {
    text: "Flash Cards",
    icon: <AutoStoriesIcon />,
    route: "/flashCard",
    requiresElite: false,
  },
  {
    text: "Lesson Planner",
    icon: <StickyNote2Icon />,
    route: "/myLessonPlans",
    requiresElite: true,
  },
  {
    text: "Billing",
    icon: <PaymentIcon />,
    url: portalURL,
  },
  // This item is conditionally added
  {
    text: "Subscription",
    icon: <UnsubscribeIcon />,
    route: "/subscrption",
    requiresNotSubscribed: true, // Add custom condition here
  },
  {
    text: "Student of Concern",
    icon: <PriorityHighIcon />,
    route: "/studentOfConcern",
    requiresElite: true,
  },
];

const secondaryListItems = [
  { text: "Settings", icon: <SettingsRoundedIcon />, route: "/settings" },
  { text: "Feedback", icon: <HelpRoundedIcon />, route: "/feedback" },
];

export default function MenuContent() {
  const navigate = useNavigate();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleNavigation = (route, url) => {
    if (url) {
      // Open the URL in a new window or tab
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      // Use React Router for internal routes
      navigate(route);
    }
  };

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.post(
          "https://eduevaluator.com/api/users/check-admin",
          { email }
        );
        setSubscriptionStatus(response.data.subscriptionStatus);
      } catch (error) {
        console.error("Error fetching subscription status:", error);
        setSubscriptionStatus("Not Subscribed"); // Default to unsubscribed on error
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Stack sx={{ flexGrow: 2, p: 1, justifyContent: "space-between" }}>
      {/* Logo Section */}
      <Stack
        sx={{
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            width: "150px", // Adjust the width as needed
            height: "auto",
          }}
        />
      </Stack>

      {/* Other Items */}
      <List dense>
        {otherItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block", mb: 2 }} // Add margin-bottom for spacing
          >
            <ListItemButton
              onClick={() => handleNavigation(item.route, item.url)}
              sx={{ py: 1.5 }} // Add padding for a larger click area
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
