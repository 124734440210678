import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import axios from "axios";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Face2Icon from '@mui/icons-material/Face2';
import SchoolIcon from "@mui/icons-material/School";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import searchBackground from "../Images/searchBackground.png";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import ErrorIcon from '@mui/icons-material/Error';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { School, AutoAwesome, AccessTime, Help } from "@mui/icons-material";
import { useTheme } from "@mui/system";

function FrostedGlassCard({ icon, title, bgColor, description, requireAccess, link }) {

  const handleCardClick = () => {
    if (!requireAccess) {
      window.location.href = link; // Navigate to the link if access is not required
    }
  };

  return (
    <Card
    onClick={handleCardClick}
    sx={{
        width: "100%",
        height: 150,
        cursor: requireAccess ? "not-allowed" : "pointer",
        opacity: requireAccess ? 0.5 : 1,
        "&:hover": {
          transform: requireAccess ? "none" : "scale(1.05)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "100%", // Full width
          height: "100%",          
        }}
      >
        {/* Front of Card */}
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: bgColor,
              width: 50,
              height: 50,
              borderRadius: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: `0 4px 12px ${bgColor}`,
              mb: 2,
            }}
          >
            {icon}
            {requireAccess && (
              <ErrorIcon
                sx={{
                  position: "absolute",
                  top: -10,
                  right: -10,
                  color: "red",
                  fontSize: 20,
                }}
              />
            )}
          </Box>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            {title}
          </Typography>
        </CardContent>

        {/* Back of Card */}
        <CardContent
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}
        >
          <Typography variant="body1" sx={{ color: "black" }}>
            {description}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}

const TeacherAdminToolsPage = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [flipped, setFlipped] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAll, setShowAll] = useState(false);
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.post(
          "https://eduevaluator.com/api/users/check-admin",
          { email: "user@example.com" } // Replace with actual email
        );
        setSubscriptionStatus(response.data.subscriptionStatus);
      } catch (error) {
        console.error("Error fetching subscription status:", error);
        setSubscriptionStatus("Not Subscribed");
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, []);
  const cards = [
    {
      icon: <School sx={{ color: "white", fontSize: 30 }} />,
      title: "Evaluation Report",
      bgColor: "#4286F0",
      description: "Explore AI-powered lesson plans for teachers.",
      requireAccess: true,
      link: "/dashboard",
    },
    {
      icon: <Face2Icon sx={{ color: "white", fontSize: 30 }} />,
      title: "Teacher Profile",
      bgColor: "#FF6B6B",
      description: "Assist with comprehensive teacher evaluations.",
      requireAccess: true,
      link: "/teacherMenu",
    },
    {
      icon: <EditCalendarIcon sx={{ color: "white", fontSize: 30 }} />,
      title: "Evaluation Scheduler",
      bgColor: "#4CAF50",
      description: "Simplify complex concepts for easier understanding.",
      requireAccess: false,
      link: "/scheduler",
    },
    {
      icon: <InsertChartIcon sx={{ color: "white", fontSize: 30 }} />,
      title: "Student Attendance",
      bgColor: "#FFC107",
      description: "Condense lengthy courses using AI technology.",
      requireAccess: false,
      link: "/attendance",
    },
    {
      icon: <ErrorIcon sx={{ color: "white", fontSize: 30 }} />,
      title: "Student of Concern",
      bgColor: "#ee7c11",
      description:
        "Helps plan excursions by suggesting destinations and activities.",
        requireAccess: false,
        link: "/studentOfConcern",
    },
    {
      icon: <PermContactCalendarIcon sx={{ color: "white", fontSize: 30 }} />,
      title: "Teacher Calcalender",
      bgColor: "#2cd390",
      description:
        "Expert in designing lesson plans tailored for cover supervisors managing classrooms in the absence of the regular teacher. ",
        requireAccess: false,
        link: "/teacherCalender",
    },
    {
      icon: <SpaceDashboardIcon sx={{ color: "white", fontSize: 30 }} />,
      title: "Teacher Event Dashboard",
      bgColor: "#7525da",
      description:
        "It offers up-to-date information, guidance, and analysis on AI-related topics.",
        requireAccess: false,
        link: "/teacherDashboard",
    },
    // Add more cards as needed
  ];

  const filteredCards = cards.filter((card) =>
    card.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
     <Box
  sx={{
    minHeight: "50vh",
   
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }}
>
  <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "100%", // Full width on mobile
      width: "100%", // Ensure full width
    }}
  >

          {/* Card Grid */}
          <Grid container spacing={2} justifyContent="center">
            {filteredCards.length > 0 ? (
              filteredCards
                .slice(0, showAll ? filteredCards.length : 8)
                .map((card, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <FrostedGlassCard
                      icon={card.icon}
                      title={card.title}
                      bgColor={card.bgColor}
                      description={card.description}
                      requireAccess={card.requireAccess}
                      link={card.link}
                    />
                  </Grid>
                ))
            ) : (
              <Typography variant="h6" color="textSecondary">
                No features found.
              </Typography>
            )}
          </Grid>
        </Container>
      </Box>

  );
});

export default TeacherAdminToolsPage;
