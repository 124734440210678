import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Box, Typography, Container } from "@mui/material";
import { Share } from "@mui/icons-material";
import axios from "axios";
import logo from "../Images/logo_white.png";
import { useNavigate } from "react-router-dom";

const FlashcardPage = () => {
  const navigate = useNavigate();

  // Folder navigation handler
  const handleFolderClick = (folderId) => {
    navigate(`/flashcards/${folderId}`);
  };

  const { folderId } = useParams(); // Get the folder ID from the URL
  const [flashcards, setFlashcards] = useState([]); // Store flashcards data
  const [currentIndex, setCurrentIndex] = useState(0); // Store current flashcard index
  const [isFlipped, setIsFlipped] = useState(false); // Track card flip state
  const [setTitle, setSetTitle] = useState(""); // To store the set name

  // Fetch flashcards when folderId changes
  useEffect(() => {
    const fetchFlashcards = async () => {
      try {
        const response = await axios.get(
          `https://eduevaluator.com/api/flashcards/findCards/${folderId}`
        );
        setFlashcards(response.data.flashcards); // Access the flashcards array from the response
        setSetTitle(response.data.setName); // Set the flashcard set name from the response
      } catch (error) {
        console.error("Error fetching flashcards:", error);
      }
    };
    fetchFlashcards();
  }, [folderId]);

  // Handle navigation between flashcards
  const handleNext = () => {
    if (currentIndex < flashcards.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setIsFlipped(false); // Reset flip state on next card
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setIsFlipped(false); // Reset flip state on previous card
    }
  };

  const handleFlip = () => {
    setIsFlipped(!isFlipped); // Toggle flip state
  };

  // Function to copy the URL to the clipboard
  const handleShare = () => {
    const currentUrl = window.location.href; // Get current URL
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        alert("Link copied to clipboard!"); // Notify the user that the link has been copied
      })
      .catch((error) => {
        console.error("Error copying link:", error);
      });
  };

  // Show loading state until flashcards are loaded
  if (!flashcards.length) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#4286F0",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      {/* Share Button at the top left */}
      <Button
        sx={{
          position: "absolute",
          top: 20,
          left: 20,
          backgroundColor: "#00bf63", // Light green color
          color: "white",
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          padding: "5px 15px",
          "&:hover": {
            backgroundColor: "#82C07C", // Slightly darker green on hover
          },
        }}
        onClick={handleShare}
        startIcon={<Share />} // Add Share icon
      >
        Share
      </Button>

      {/* Display Flashcard Set Title */}
      <Typography variant="h4" sx={{ mb: 2 }}>
        {setTitle} {/* Title of the flashcard set */}
      </Typography>

      {/* Flashcard with Flip Animation */}
      <Box
        sx={{
          width: 700,
          height: 500,
          perspective: "1000px", // Add perspective for 3D effect
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            transformStyle: "preserve-3d",
            transition: "transform 0.8s",
            transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
            cursor: "pointer",
          }}
          onClick={handleFlip}
        >
          {/* Front of the Card */}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backfaceVisibility: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              color: "black",
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">
              {flashcards[currentIndex].term}
            </Typography>
          </Box>

          {/* Back of the Card */}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backfaceVisibility: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              color: "black",
              borderRadius: 2,
              transform: "rotateY(180deg)",
            }}
          >
            <Typography variant="h6">
              <Container>{flashcards[currentIndex].definition}</Container>
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Display current index and total cards */}
      <Typography variant="body1" sx={{ mt: 2 }}>
        {currentIndex + 1} of {flashcards.length}
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
        <Button
          fullWidth
          style={{
            backgroundColor: "#FCCC5B",
            color: "black",
            width: 200,
            borderRadius: 20,
          }}
          onClick={handleBack}
          disabled={currentIndex === 0}
          sx={{ marginRight: 2 }}
        >
          Back
        </Button>
        <Button
          fullWidth
          style={{
            backgroundColor: "#FCCC5B",
            color: "black",
            width: 200,
            borderRadius: 20,
          }}
          onClick={handleNext}
          disabled={currentIndex === flashcards.length - 1}
        >
          Next
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <img
          src={logo} // Replace with your logo path or URL
          alt="Logo"
          style={{
            width: 100, // Set the logo size
            height: "auto",
            objectFit: "contain",
          }}
        />
      </Box>
    </Box>
  );
};

export default FlashcardPage;
