import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import axios from "axios";
import Cookies from "js-cookie";
import { IconButton, Typography, TextField, Card } from "@mui/material";
import { format, startOfWeek, endOfWeek, eachDayOfInterval } from "date-fns";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const AssessmentsGraph = () => {
  const [assessments, setAssessments] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(new Date());
  const [graphData, setGraphData] = useState([]);
  const [calendarVisible, setCalendarVisible] = useState(false);

  useEffect(() => {
    const fetchAssessments = async () => {
      const userEmail = Cookies.get("email");
      try {
        const response = await axios.get(
          `https://eduevaluator.com/api/teachercal/all-assessments?userEmail=${userEmail}`
        );
        if (response.data && Array.isArray(response.data)) {
          setAssessments(response.data); // Set the new format (array of date/count)
        } else {
          setAssessments([]); // In case the response data is empty or not an array
        }
      } catch (error) {
        console.error("Error fetching assessments:", error);
        setAssessments([]); // In case of an error, set an empty array
      }
    };

    fetchAssessments();
  }, []);

  useEffect(() => {
    const weekStart = startOfWeek(selectedWeek, { weekStartsOn: 1 }); // Monday
    const weekEnd = endOfWeek(selectedWeek, { weekStartsOn: 1 });

    // Generate each day of the week
    const days = eachDayOfInterval({ start: weekStart, end: weekEnd }).map(
      (day) => format(day, "yyyy-MM-dd") // Format as yyyy-MM-dd
    );

    // Map through each day to prepare graph data
    const data = days.map((day) => {
      const dayAssessment = assessments.find(
        (assessment) => assessment.date === day
      );
      const count = dayAssessment ? dayAssessment.count : 0;

      return {
        day: day, // Use the date string directly
        count: count,
      };
    });

    setGraphData(data);
  }, [selectedWeek, assessments]);

  const toggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  const handleDateChange = (e) => {
    const newDate = new Date(e.target.value);
    setSelectedWeek(newDate);
  };

  return (
    <div style={{ position: "relative" }}>
      <IconButton
        onClick={toggleCalendar}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          backgroundColor: "#4286F0",
          color: "white",
          borderRadius: "50%",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <MoreVertIcon />
      </IconButton>

      {calendarVisible && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            right: "10px",
            backgroundColor: "white",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            zIndex: 10,
          }}
        >
          <TextField
            type="date"
            label="Select Week"
            value={format(selectedWeek, "yyyy-MM-dd")}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      )}

      <Card
        style={{
          padding: "20px",
          backgroundColor: "#f5f5f5",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h6" align="center" sx={{ marginBottom: "20px" }}>
          Workload
        </Typography>

        {graphData.length === 0 ? (
          <Typography variant="body1" style={{ color: "#666" }}>
            No workload available for the selected week.
          </Typography>
        ) : (
          <BarChart
            width={600}
            height={300}
            data={graphData}
            margin={{ top: 30, bottom: 40 }}
          >
            <XAxis
              dataKey="day"
              tick={{ fontSize: 10, fontWeight: "bold", fill: "#666" }}
            />
            <YAxis tick={{ fontSize: 14, fontWeight: "bold", fill: "#666" }} />
            <Tooltip
              contentStyle={{
                backgroundColor: "#333",
                color: "#fff",
                borderRadius: "8px",
              }}
            />
            <Legend
              wrapperStyle={{ paddingTop: 10, fontSize: "14px", color: "#666" }}
            />
            <Bar
              dataKey="count"
              fill="#82ca9d"
              radius={[10, 10, 0, 0]}
              barSize={20}
            />
          </BarChart>
        )}
      </Card>
    </div>
  );
};

export default AssessmentsGraph;
