import React, { useState,useCallback } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import JSZip from "jszip";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the clipboard icon
import SchoolIcon from "@mui/icons-material/School";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import axios from "axios";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function PowerPointCondensor() {
  // State variables
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [additionalQuestion, setAdditionalQuestion] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [teacherFirstName, setTeacherFirstName] = useState("");
  const [teacherLastName, setTeacherLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [levelOfUnderstanding, setLevelOfUnderstanding] = useState("");
  const [subjectDifficulty, setSubjectDifficulty] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response
  const [parsedSlides, setParsedSlides] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
  
    if (file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = event.target.result;
  
        // Load .pptx content as a zip file
        const zip = await JSZip.loadAsync(data);
  
        // Extract slide contents
        const slidePaths = Object.keys(zip.files)
          .filter((path) => path.startsWith("ppt/slides/slide") && path.endsWith(".xml"))
          .sort(); // Slides are named slide1.xml, slide2.xml, etc.
  
        // Check if the number of slides is greater than 10
        if (slidePaths.length > 10) {
          toast.error("The presentation has more than 10 slides. Please upload a presentation with 10 or fewer slides.");
          return; // Prevents the handleSubmit from being called
        }
  
        let allSlidesText = ""; // Variable to store all text from all slides
  
        for (const slidePath of slidePaths) {
          const slideContent = await zip.files[slidePath].async("text"); // Extract XML
  
          // Parse the XML and extract text
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(slideContent, "application/xml");
          const textElements = xmlDoc.getElementsByTagName("a:t");
  
          // Collect text content from <a:t> tags
          for (let i = 0; i < textElements.length; i++) {
            allSlidesText += textElements[i].textContent + " "; // Append to the main variable
          }
        }
  
        // Log the combined text from all slides
        console.log(`${allSlidesText.trim()}`);
        setParsedSlides(allSlidesText.trim());
        handleSubmit();
      };
      reader.readAsArrayBuffer(file);
      
    } else {
      toast.error("Please upload a valid PowerPoint file.");
    }
  }, []);
  
  
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    },
    
  });

  // Mock handler functions
  const handleTeacherFirstNameChange = (e) =>
    setTeacherFirstName(e.target.value);
  const handleTeacherLastNameChange = (e) => setTeacherLastName(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleGradeLevelChange = (e) => setGradeLevel(e.target.value);
  const handleLevelOfUnderstandingChange = (e) =>
    setLevelOfUnderstanding(e.target.value);
  const handleSubjectDifficultyChange = (e) =>
    setSubjectDifficulty(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const saveDraft = () => alert("Draft saved!");
  // Function to log all form data
  // Function to log all form data
  const handleSubmit = async () => {

    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `Give a summary of this text. In text only.${parsedSlides}. Ignore places where Images are refrenced.`;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />{" "}
      {/* Render loading modal */}
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
            
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
            PowerPoint Summarizer
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                This tool extracts key information, including slide titles, bullet points, and text content, and generates an easy-to-read overview.
                </Typography>
              </Box>
              <Grid container spacing={2}>   
                <Grid item sm={12}>
                <Box
      {...getRootProps()}
      sx={{
        border: "2px dashed #ccc",
        borderRadius: "10px",
        padding: "20px",
        textAlign: "center",
        backgroundColor: "#f9f9f9",
        cursor: "pointer",
        transition: "0.3s",
            marginBottom:"10px",
        "&:hover": { backgroundColor: "#f0f0f0" },
      }}
    >
      <input {...getInputProps()} />
      <UploadFileIcon style={{ fontSize: "48px", color: "#999" }} />
      <Typography variant="h6" sx={{ mt: 2, color: "#666" }}>
        Drag and drop a PowerPoint file here, or click to upload
      </Typography>
    </Box> 
                </Grid>
               
                
              </Grid>

              <Box>
                {uploadedFiles.map((file, index) => (
                  <Chip
                    key={index}
                    label={file}
                    onDelete={() => {
                      setUploadedFiles(
                        uploadedFiles.filter((_, i) => i !== index)
                      );
                    }}
                    sx={{ margin: 1 }}
                  />
                ))}
              </Box>          
            </Container>
          </Accordion>
        </Box>
        {/* Modal for displaying results */}
        <Dialog
          open={modalOpen}
          onClose={() => {}} // Disable closing by clicking outside
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            />
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default PowerPointCondensor;
