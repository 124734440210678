import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Checkbox,
  FormLabel,
  FormControlLabel,
  Tooltip,
  Menu,
} from "@mui/material";
import {
  format,
  startOfWeek,
  addDays,
  addMonths,
  subMonths,
  set,
  startOfMonth,
  endOfMonth,
} from "date-fns";

const TeacherCalComponent = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor
  const [selectedEvent, setSelectedEvent] = useState(null); // Event for deletion
  const [selectedDate, setSelectedDate] = useState(new Date(2025, 0, 1)); // Start with January 2025
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupInfo, setPopupInfo] = useState({});
  const [type, setType] = useState("");
  const [formData, setFormData] = useState({
    type: "",
    title: "",
    date: "",
    description: "",
    userEmail: "",
    time: "",
    deadline: "",
    gradeLevel: "",
  });

  const [itemCreated, setItemCreated] = useState(false);
  const [eventDetails, setEventDetails] = useState(null); // For storing event details
  const [className, setClassName] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [deadline, setDeadline] = useState(false);
  const [deadlineDate, setDeadlineDate] = useState("");
  const [events, setEvents] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete confirmation dialog
  const [eventToDelete, setEventToDelete] = useState(null); // Store the event to be deleted

  const fetchEvents = async () => {
    const userEmail = Cookies.get("email");
    try {
      const response = await axios.get(
        "https://eduevaluator.com/api/teachercal/events",
        {
          params: {
            userEmail,
          },
        }
      );
      console.log(response.data); // Check the structure of the response

      // Transform each event into a newEvent object
      const newEvents = response.data.map((event) => ({
        id: event._id, // Replace with actual property names
        className: event.title, // Customize as needed
        day: event.date,
        time: event.time,
        description: event.description,
        type: event.type,
        gradeLevel: event.gradeLevel,
        color: getEventColor(event.type),
        ...event.extraData,
      }));

      setEvents([...newEvents]); // Update state with the transformed events
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    fetchEvents(); // Call the function to fetch events
  }, [selectedDate]);

  // Helper function to get the start of the week
  const getStartOfWeek = (date) => startOfWeek(date, { weekStartsOn: 0 });

  const handleRightClick = (event, eventData) => {
    event.preventDefault(); // Prevent the default right-click menu
    setSelectedEvent(eventData);
    setAnchorEl(event.currentTarget); // Set the menu anchor
  };

  const handleCloseMenu = () => {
    setAnchorEl(null); // Close the menu
  };

  // Navigate to previous week
  const goBack = () => {
    const prevWeekStart = addDays(getStartOfWeek(selectedDate), -7);
    const prevMonthStart = startOfMonth(prevWeekStart);
    if (prevWeekStart >= prevMonthStart) {
      setSelectedDate(prevWeekStart);
    }
  };

  // Navigate to next week
  const goForward = () => {
    const nextWeekStart = addDays(getStartOfWeek(selectedDate), 7);
    const nextMonthEnd = endOfMonth(nextWeekStart);
    if (nextWeekStart <= nextMonthEnd) {
      setSelectedDate(nextWeekStart);
    }
  };

  const handleDateChange = (event) => {
    const date = new Date(event.target.value);
    setSelectedDate(date);
  };

  const handleDayClick = (day, offsetY) => {
    const hour = Math.floor(offsetY / 50);
    const time = set(new Date(), { hours: hour + 6, minutes: 0 }); // 6AM as the starting point
    setPopupInfo({ day, time: format(time, "ha") });
    setPopupOpen(true);
    setIsEditing(false); // Not editing a pre-existing event
  };

  const handleSave = async () => {
    // Create a new event object
    console.log(popupInfo._id);
    const newEvent = {
      id: popupInfo._id, // Unique ID for each event
      day: popupInfo.day,
      time: popupInfo.time,
      type: popupInfo.type,
      className: popupInfo.className,
      gradeLevel: popupInfo.gradeLevel,
      deadline: popupInfo.deadlineDate,
      todoTask: popupInfo.todoTask,
      color: getEventColor(popupInfo.type), // Get the color based on type
    };

    // Do the put
    if (isEditing) {
      try {
        const userEmail = Cookies.get("email");
        // Send the new event to the backend using a POST request
        const response = await axios.put(
          `https://eduevaluator.com/api/teachercal/events/${popupInfo.id}`,
          {
            userEmail: userEmail,
            type: popupInfo.type,
            title: popupInfo.className,
            gradeLevel: popupInfo.gradeLevel,
            date: `${popupInfo.day}`,
            time: `${popupInfo.time}`, // Format date as a string
            description: popupInfo.todoTask,
            todoTask: popupInfo.todoTask, // A short description
            deadlineDate: popupInfo.deadlineDate,
          }
        );

        // If the event is saved successfully, update the state
        setEvents([...events, newEvent]);
        fetchEvents();
        setPopupOpen(false);
      } catch (error) {
        // Handle errors
        console.error("Error saving event:", error);
      }
    } else {
      try {
        // Getting the date
        // Get the current year
        const currentYear = 2025; // Get the current year dynamically

        // Extract the date parts from the popupInfo.day string (assuming format "Tuesday, December 31")
        const [weekday, monthDay] = popupInfo.day.split(", ");
        const [month, day] = monthDay.split(" ");

        // Map month names to month numbers (e.g., "January" -> 0, "December" -> 11)
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const monthIndex = monthNames.indexOf(month);

        // Convert the day string to a full date format
        const convertedDate = new Date(
          currentYear,
          monthIndex,
          parseInt(day, 10)
        );

        const userEmail = Cookies.get("email");
        // Send the new event to the backend using a POST request
        const response = await axios.post(
          "https://eduevaluator.com/api/teachercal/add-event",
          {
            userEmail: userEmail,
            type: popupInfo.type,
            title: popupInfo.className,
            gradeLevel: popupInfo.gradeLevel,
            convertedDate: convertedDate,
            date: `${popupInfo.day}`,
            time: `${popupInfo.time}`, // Format date as a string
            description: popupInfo.description, // A short description
            deadlineDate: popupInfo.deadlineDate,
            todoTask: popupInfo.todoTask,
          }
        );

        // If the event is saved successfully, update the state
        setEvents([...events, newEvent]);
        fetchEvents();
        setPopupOpen(false);
      } catch (error) {
        // Handle errors
        console.error("Error saving event:", error);
      }
    }
  };
  const handleEventClick = (event) => {
    console.log(event);
    setPopupInfo({
      id: event.id,
      className: event.className,
      day: event.day,
      time: event.time,
      type: event.type,
      description: event.description,
      gradeLevel: event.gradeLevel,
      deadlineDate: event.deadlineDate,
      color: getEventColor(event.type),
      todoTask: event.todoTask,
    });
    setPopupOpen(true);
    setIsEditing(true);
  };

  const getEventColor = (type) => {
    switch (type) {
      case "To-Do":
        return "red";
      case "Quiz":
        return "green";
      case "Test":
        return "orange";
      case "Homework":
        return "purple";
      default:
        return "gray";
    }
  };

  const handleDelete = async (event) => {
    console.log(event.id);
    try {
      // Call the backend delete endpoint
      const response = await axios.delete(
        `https://eduevaluator.com/api/teachercal/events/${event.id}`
      );

      // Log the success message (optional)
      console.log(response.data.message);

      // Update the events state by removing the deleted event
      setEvents(events.filter((e) => e.id !== event.id));
      fetchEvents(); // Optionally refresh from the backend
      // Close the delete confirmation dialog
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const cancelDelete = () => {
    // Close the delete confirmation dialog without deleting
    setDeleteDialogOpen(false);
  };

  const startOfSelectedWeek = getStartOfWeek(selectedDate);
  const daysOfWeek = Array.from({ length: 7 }, (_, i) =>
    addDays(startOfSelectedWeek, i)
  );

  const hours = Array.from({ length: 14 }, (_, i) => i + 6); // Time from 6AM to 8PM

  const currentYear = 2025; // Fixed to 2025 as per your request
  const years = [currentYear]; // Only 2025 for year selection
  const months = Array.from(
    { length: 12 },
    (_, i) => new Date(currentYear, i, 1)
  ); // Generate months for 2025

  return (
    <Box sx={{ padding: 2, width: "100%" }}>
      <Typography variant="h5" gutterBottom>
        Week View Calendar
      </Typography>

      {/* Year and Month Dropdown */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <Select
          value={selectedDate.toISOString().split("T")[0].slice(0, 7)}
          onChange={handleDateChange}
          fullWidth
        >
          {months.map((month, i) => (
            <MenuItem
              key={i}
              value={month.toISOString().split("T")[0].slice(0, 7)}
            >
              {format(month, "MMMM yyyy")}
            </MenuItem>
          ))}
        </Select>

        <Box sx={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
          <Tooltip title="Go to Previous Week" arrow>
            <Button onClick={goBack} variant="outlined">
              Prev
            </Button>
          </Tooltip>
          <Tooltip title="Go to Next Week" arrow sx={{ marginLeft: 2 }}>
            <Button onClick={goForward} variant="outlined">
              Next
            </Button>
          </Tooltip>
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Box>
            <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
              Time
            </Typography>
            {hours.map((hour) => (
              <Typography
                key={hour}
                sx={{
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {format(set(new Date(), { hours: hour, minutes: 0 }), "ha")}
              </Typography>
            ))}
          </Box>
        </Grid>

        {daysOfWeek.map((day, index) => (
          <Grid
            item
            xs
            key={index}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: 2,
                height: "700px", // Increased height for better view
                cursor: "pointer",
                padding: 1,
                width: "100%", // Make the day box use available space
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
              onClick={(e) =>
                handleDayClick(
                  format(day, "EEEE, MMMM dd"),
                  e.nativeEvent.offsetY
                )
              }
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 1,
                  color: "#333",
                }}
              >
                {format(day, "EEE dd")}
              </Typography>

              {/* Render Events */}
              {events
                .filter((event) => event.day === format(day, "EEEE, MMMM dd"))
                .map((event) => (
                  <Box
                    key={event.id}
                    sx={{
                      backgroundColor: event.color,
                      padding: "8px",
                      marginBottom: "4px",
                      borderRadius: "4px",
                      color: "white",
                      fontSize: "14px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering day click
                      handleEventClick(event);
                    }}
                    onContextMenu={(e) => handleRightClick(e, event)} // Handle right-click
                  >
                    {event.type} - {event.className}
                  </Box>
                ))}
            </Box>
          </Grid>
        ))}
      </Grid>

      <Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
        <DialogTitle>{isEditing ? "Edit Event" : "Create Event"}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            {popupInfo.day} - {popupInfo.time}
          </Typography>
          <FormLabel>Event Type</FormLabel>
          <FormControl fullWidth margin="dense">
            <Select
              value={popupInfo.type || ""}
              onChange={(e) =>
                setPopupInfo({ ...popupInfo, type: e.target.value })
              }
            >
              <MenuItem value="To-Do">To-Do</MenuItem>
              <MenuItem value="Quiz">Quiz</MenuItem>
              <MenuItem value="Test">Test</MenuItem>
              <MenuItem value="Homework">Homework</MenuItem>
            </Select>
          </FormControl>

          {popupInfo.type === "To-Do" && (
            <div>
              <FormLabel>To-Do Task</FormLabel>

              <TextField
                value={popupInfo.description || ""}
                onChange={(e) =>
                  setPopupInfo({ ...popupInfo, description: e.target.value })
                }
                fullWidth
                margin="normal"
              />
            </div>
          )}

          <FormLabel>Class Name</FormLabel>
          <TextField
            fullWidth
            margin="dense"
            value={popupInfo.className || ""}
            onChange={(e) =>
              setPopupInfo({ ...popupInfo, className: e.target.value })
            }
          />
          <FormLabel>Grade Level</FormLabel>
          <TextField
            fullWidth
            margin="dense"
            value={popupInfo.gradeLevel || ""}
            onChange={(e) =>
              setPopupInfo({ ...popupInfo, gradeLevel: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPopupOpen(false)}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={cancelDelete}>
        <DialogTitle>Are you sure you want to delete this event?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setPopupOpen(false)}>Cancel</Button>
          <Button onClick={handleSave}>{isEditing ? "Update" : "Save"}</Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{ onMouseLeave: handleCloseMenu }}
      >
        <MenuItem onClick={() => handleDelete(selectedEvent)}>
          Delete Event
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TeacherCalComponent;
