import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  FormLabel,
  DialogContentText,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Cookies from "js-cookie";

const CardView = () => {
  const navigate = useNavigate();
  const handleFolderClick = (folderId) => {
    window.open(`/flashcards/${folderId}`, "_blank");
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteFolderId, setDeleteFolderId] = useState(null);
  const [cards, setCards] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newCardTitle, setNewCardTitle] = useState("");
  const [loading, setLoading] = useState(true);

  // New state to manage terms and definitions
  const [terms, setTerms] = useState([{ term: "", definition: "" }]);

  // Handler to open the Add card dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const userEmail = Cookies.get("email");
  const fetchCards = async () => {
    const userEmail = Cookies.get("email");
    try {
      setLoading(true);

      const response = await axios.get(
        "https://eduevaluator.com/api/flashcards/get-flashcards",
        { params: { userEmail } }
      );
      setCards(response.data);
    } catch (error) {
      setCards([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCards();
  }, [userEmail]);

  // Handler to close the Add card dialog
  const handleCloseDialog = (event, reason) => {
    if (reason === "backdropClick") {
      // Prevent closing the dialog if it's a backdrop click
      return;
    }
    setOpenDialog(false);
    setNewCardTitle("");
    setTerms([{ term: "", definition: "" }]); // Reset the terms to initial state
  };

  // Handle the dynamic addition of a new term/definition field
  const handleAddTerm = () => {
    setTerms([...terms, { term: "", definition: "" }]);
  };

  // Function to handle the delete button click
  const handleDeleteClick = (e, folderId) => {
    e.stopPropagation(); // Prevent the card from being clicked when delete is clicked
    setDeleteFolderId(folderId);
    setOpenDeleteModal(true);
  };

  // Function to handle deletion
  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(
        `https://eduevaluator.com/api/flashcards/delete-folder/${deleteFolderId}`
      );
      setCards(cards.filter((card) => card._id !== deleteFolderId)); // Update local state after deletion
      setOpenDeleteModal(false);
    } catch (error) {
      console.error("Error deleting folder:", error);
      alert("Failed to delete folder. Please try again.");
    }
  };

  // Function to handle modal close without deleting
  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
    setDeleteFolderId(null);
  };

  // Handle changes in the term or definition fields
  const handleTermChange = (index, field, value) => {
    const updatedTerms = [...terms];
    updatedTerms[index][field] = value;
    setTerms(updatedTerms);
  };

  const handleSave = async () => {
    const userEmail = Cookies.get("email");
    const setName = newCardTitle;
    const flashcards = terms.filter((term) => term.term && term.definition);

    if (!setName || flashcards.length === 0) {
      alert("Please provide a set name and at least one term/definition.");
      return;
    }

    try {
      const response = await axios.post(
        "https://eduevaluator.com/api/flashcards/flashcard-set",
        {
          userEmail,
          setName,
          flashcards,
        }
      );

      console.log("Response:", response.data);
      fetchCards();
      const newCard = { setName: setName, _id: response.data._id };
      setCards([...cards, newCard]);
      handleCloseDialog();
    } catch (error) {
      console.error("Error creating flashcard set:", error);
      alert("Failed to create flashcard set. Please try again.");
    }
  };

  return (
    <div>
      {/* Grid container for cards */}
      <Grid
        container
        spacing={3} // Increase spacing between items
        justifyContent="flex-start" // Align items to the start
        alignItems="stretch" // Ensure items stretch equally
      >
        {/* Static "Add Card" with the Add Icon */}
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Card
            sx={{
              minWidth: 150,
              height: 200,
              marginRight: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              border: "1px dashed #4286F0",
            }}
            onClick={handleOpenDialog}
          >
            <CardContent sx={{ textAlign: "center" }}>
              <IconButton>
                <AddIcon sx={{ fontSize: 50, color: "#4286F0" }} />
              </IconButton>
              <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                Add Flashcards
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Render existing cards */}
        {cards.map((card, index) => (
          <Grid item sm={3} key={index}>
            <Card
              onClick={() => handleFolderClick(card._id)}
              sx={{
                minWidth: 150,
                marginRight: 10,
                height: 200,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #4286F0",
                cursor: "pointer",
                position: "relative", // Ensure the card is positioned relative
              }}
            >
              <CardContent sx={{ textAlign: "center" }}>
                <FolderIcon sx={{ fontSize: 50, color: "#4286F0" }} />
                <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                  {card.setName}
                </Typography>
              </CardContent>

              {/* Delete Button */}
              <IconButton
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  zIndex: 1, // Ensure it's on top of other elements
                }}
                onClick={(e) => handleDeleteClick(e, card._id)}
              >
                <DeleteIcon sx={{ color: "#ff5757" }} />
              </IconButton>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={openDeleteModal} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this set of flash cards? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} style={{ color: "red" }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Card Dialog */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "80%", // Adjust the width as needed
            maxWidth: "1000px", // Optionally set a max width
          },
        }}
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Add New Card</DialogTitle>
        <DialogContent>
          {/* Render the flashcard set name field */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel>Flashcard set name</FormLabel>
              <TextField
                fullWidth
                style={{ marginBottom: 10 }}
                variant="outlined"
                value={newCardTitle}
                onChange={(e) => setNewCardTitle(e.target.value)}
              />
            </Grid>
          </Grid>

          {/* Render input fields for terms and definitions */}
          {terms.map((term, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={6}>
                <FormLabel>Term</FormLabel>
                <TextField
                  fullWidth
                  style={{ marginBottom: 10 }}
                  variant="outlined"
                  value={term.term}
                  onChange={(e) =>
                    handleTermChange(index, "term", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Definition</FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={term.definition}
                  onChange={(e) =>
                    handleTermChange(index, "definition", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          ))}

          {/* Add Term button */}
          <Button
            sx={{ mt: 2, backgroundColor: "grey", color: "white" }}
            onClick={handleAddTerm}
          >
            Add Term
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CardView;
