import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Container,
  Typography,
  Chip,
  Grid,
  Box,
  Divider,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Pagination,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const AssessmentList = ({ itemsToShow }) => {
  const [assessments, setAssessments] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAllDialog, setShowAllDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Items per page for the popup

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const userEmail = Cookies.get("email");
        const response = await axios.get(
          `https://eduevaluator.com/api/teachercal/assessments?userEmail=${userEmail}`
        );
        setAssessments(response.data);
      } catch (error) {
        console.error("Error fetching assessments:", error);
      }
    };

    fetchAssessments();
  }, []);

  const parseDate = (dateString) => {
    const [dayOfWeek, month, day] = dateString.split(" ");
    return { dayOfWeek, month, day };
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleShowAllClick = () => {
    setShowAllDialog(true);
    handleMenuClose();
  };

  const handleDialogClose = () => {
    setShowAllDialog(false);
  };

  const handlePageChange = (_, page) => {
    setCurrentPage(page);
  };

  const paginatedAssessments = assessments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const visibleAssessments = itemsToShow
    ? assessments.slice(0, itemsToShow)
    : assessments;

  return (
    <Container>
      <Card
        style={{
          padding: "20px",
          backgroundColor: "#f5f5f5",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          height: "380px",
          position: "relative",
        }}
      >
        <IconButton
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            backgroundColor: "#4286F0",
            color: "white",
            borderRadius: "50%",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
          onClick={handleMenuOpen}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleShowAllClick}>Show All</MenuItem>
        </Menu>

        <Typography variant="h6" align="center" sx={{ marginBottom: "20px" }}>
          Upcoming Assessment
        </Typography>
        {visibleAssessments.length > 0 ? (
          visibleAssessments.map((assessment, index) => {
            const { dayOfWeek, month, day } = parseDate(assessment.date);

            return (
              <React.Fragment key={assessment.id}>
                <Box sx={{ marginBottom: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          position: "relative",
                          width: "60px",
                          height: "60px",
                          backgroundColor: "#D3D3D3",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            width: "100%",
                            height: "10%",
                            backgroundColor: "#1976d2",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            zIndex: 1,
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {day}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            textTransform: "capitalize",
                            zIndex: 1,
                            color: "black",
                            fontSize: "10px",
                          }}
                        >
                          {month}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {assessment.title}
                      </Typography>
                      <Typography variant="body1" sx={{ color: "grey" }}>
                        {assessment.date}
                      </Typography>
                      <Chip
                        label={assessment.type}
                        sx={{
                          mt: 1,
                          backgroundColor:
                            assessment.type === "Quiz" ? "#F6CF71" : "#F89C74",
                          color:
                            assessment.type === "Quiz" ? "#F6CF71" : "white",
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        variant="body2"
                        align="right"
                        sx={{ color: "grey" }}
                      >
                        {assessment.time}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {index < visibleAssessments.length - 1 && (
                  <Divider
                    sx={{
                      borderColor: "#D3D3D3",
                      borderStyle: "dashed",
                      marginBottom: 2,
                    }}
                  />
                )}
              </React.Fragment>
            );
          })
        ) : (
          <Typography variant="h6">No assessments found</Typography>
        )}
      </Card>

      {/* Show All Dialog */}
      <Dialog
        open={showAllDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>All Assessments</DialogTitle>
        <DialogContent>
          {paginatedAssessments.map((assessment) => (
            <Box key={assessment.id} sx={{ marginBottom: 2 }}>
              <Typography variant="h6">{assessment.title}</Typography>
              <Typography variant="body1">{assessment.date}</Typography>
              <Divider sx={{ marginY: 2 }} />
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Pagination
            count={Math.ceil(assessments.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
          />
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AssessmentList;
