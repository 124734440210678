import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
  CircularProgress,
  Typography,
  Box,
  IconButton,
  Container,
  Card,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const TodoList = ({ userEmail, visibleCount = 5 }) => {
  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchTodos = async () => {
      try {
        const userEmail = Cookies.get("email");
        const response = await axios.get(
          `https://eduevaluator.com/api/teachercal/todos/${userEmail}`
        );
        setTodos(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching to-do list:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTodos();
  }, [userEmail]);

  const handleComplete = async (todoId) => {
    try {
      const response = await axios.patch(
        `https://eduevaluator.com/api/teachercal/todos/${todoId}/complete`
      );
      const updatedTodos = response.data.incompleteTodos; // Get incomplete todos from the response

      // Update the todos state with the incomplete items
      setTodos(updatedTodos);
    } catch (error) {
      console.error("Error marking todo as complete:", error);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleShowAll = () => {
    setShowAll(true);
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setShowAll(false);
    setPage(0);
  };

  const handleNextPage = () => {
    if ((page + 1) * itemsPerPage < todos.length) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  if (loading) return <CircularProgress />;

  if (todos.length === 0) {
    return (
      <Container>
        <Card
          style={{
            padding: "20px",
            backgroundColor: "#f5f5f5",
            boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          <Typography variant="h6" align="center" sx={{ marginTop: "20px" }}>
            No to-dos found!
          </Typography>
        </Card>
      </Container>
    );
  }

  const visibleTodos = todos.slice(0, visibleCount);
  const paginatedTodos = todos.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  return (
    <Container>
      <Card
        style={{
          padding: "20px",
          backgroundColor: "#f5f5f5",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <IconButton
            onClick={handleMenuClick}
            style={{
              backgroundColor: "#4286F0",
              color: "white",
              borderRadius: "50%",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleShowAll}>Show All</MenuItem>
          </Menu>
        </Box>

        <Typography variant="h6" align="center" sx={{ marginBottom: "20px" }}>
          To-Do List
        </Typography>
        {visibleTodos.map((todo) => (
          <Box
            key={todo._id}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              padding: "12px",
              borderBottom: "2px dashed #D3D3D3",
            }}
          >
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={() => handleComplete(todo._id)}
                sx={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: todo.completed ? "green" : "transparent",
                  border: "2px solid green",
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  textDecoration: todo.completed ? "line-through" : "none",
                  color: todo.completed ? "grey" : "black",
                  marginLeft: "8px",
                }}
              >
                {todo.description}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textDecoration: todo.completed ? "line-through" : "none",
                  color: todo.completed ? "grey" : "black",
                  marginLeft: "8px",
                  fontWeight: "bold", // Add this line to make the text bold
                }}
              >
                {todo.title}
              </Typography>
            </Box>
          </Box>
        ))}
      </Card>

      <Dialog
        open={showAll}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>All To-Do Items</DialogTitle>
        <DialogContent>
          <List>
            {paginatedTodos.map((todo) => (
              <ListItem key={todo._id}>
                <Checkbox
                  checked={todo.completed}
                  onChange={() => handleComplete(todo._id)}
                  color="primary"
                />
                <ListItemText primary={todo.title} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePrevPage} disabled={page === 0}>
            Previous
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={(page + 1) * itemsPerPage >= todos.length}
          >
            Next
          </Button>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TodoList;
